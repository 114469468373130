import { graphql } from "gatsby";
import React from "react";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Button from "../components/Button";
import BlockContent from "../components/BlockContent";

const UlikeLaan = ({
  data: {
    sanityDifferentLoans: {
      mainHeading,
      unseucureLoanText1,
      unsecureLoanText2,
      mathExample1,
      mathExample2,
    },
  },
}) => {
  return (
    <>
      <Nav />
      <div className="text-heading-4 md:text-heading-2 text-gray-dark mb-2 py-8 px-8">
        {mainHeading}
      </div>

      <div className="flex flex-col md:flex-row px-16 pt-16 place-content-evenly md:space-x-64">
        <div className="p-4 text-center flex flex-col place-content-center ">
          <div className="text-heading-4">
            <BlockContent blocks={unseucureLoanText1} />
          </div>
          <div>
            <Button href="https://affiliates.instabank.no/scripts/cqefyk?a_aid=0056&a_bid=4fa1ed88&chan=ryddi">
              Bekreft
            </Button>
          </div>
        </div>
        <div className="p-4 text-center flex flex-col place-content-center">
          <div className="text-heading-4">
            <BlockContent blocks={unsecureLoanText2} />
          </div>
          <div>
            <Button href="https://affiliates.instabank.no/scripts/cqefyk?a_aid=0056&a_bid=2624cd1d&chan=ryddi">
              Bekreft
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row px-16 pt-16 place-content-center">
        <div className="flex flex-col md:flex-row place-content-between p-4 text-center">
          <BlockContent blocks={mathExample1} />
        </div>
        <div className="p-4 text-center md:border-l-2 border-accent flex flex-col place-content-center">
          <BlockContent blocks={mathExample2} />
        </div>
      </div>
      <Footer />
      <CookieBanner />
    </>
  );
};

export const query = graphql`
  query {
    sanityDifferentLoans(_id: { regex: "/differentLoans$/" }) {
      mainHeading
      unseucureLoanText1: _rawUnseucureLoanText1
      unsecureLoanText2: _rawUnsecureLoanText2
      mathExample1: _rawMathExample1
      mathExample2: _rawMathExample2
    }
  }
`;

export default UlikeLaan;
